<template>
  <div class="d-flex flex-wrap justify-space-between align-start pb-2">
    <div class="d-flex justify-space-between align-start custom-width">
      <ProductFacetFilterMobile
        v-if="view === 'list'"
        :facets="facets"
        :facetsFilter="facetsFilter"
        :filteredProductsCount="filteredProductsCount"
        :hideFilterString="hideFilterString"
        @handleFacetFilterMobile="handleFacetFilterMobile"
      />
      <div class="select">
        <v-select
          label="Visualizza"
          outlined
          dense
          solo
          flat
          append-icon="mdi-chevron-down"
          :items="viewOptions"
          :value="view"
          @change="handleView"
          hide-details
          color="secondary darken-1"
        />
      </div>
      <div class="select">
        <v-select
          :label="$t('sort')"
          outlined
          dense
          solo
          flat
          append-icon="mdi-chevron-down"
          class="ps-4 pt-0"
          color="secondary darken-1"
          :items="sortOptions"
          :value="sortFilter"
          @change="handleSortFilter"
          hide-details
        />
      </div>
    </div>
    <div class="d-flex justify-space-between align-start custom-width">
      <!-- <v-checkbox
        v-if="!cart"
        v-model="status"
        hide-details
        :label="$t('common.insertAllToCart')"
        class="pr-3"
        @click="addAll"
      ></v-checkbox> -->
      <v-menu offset-y v-if="cart">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="secondary"
            v-bind="attrs"
            v-on="on"
            :disabled="totalItems < 1"
            >Azioni multiple
            <v-icon right dark>
              mdi-dots-horizontal
            </v-icon></v-btn
          ></template
        >
        <MultipleAction :cart="cart" />
      </v-menu>
      <v-btn v-else @click="addAllToCart" color="secondary"
        >{{ $t("orders.list.addAllToCart") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ProductFacetFilterMobile from "./ProductFacetFilterMobile.vue";
//import CustomMarketSuiteService from "../../../service/marketsuiteCustomService";
import login from "@/commons/mixins/login";
import { mapGetters, mapActions } from "vuex";
import MultipleAction from "@/components/cart/MultipleAction.vue";
export default {
  name: "ProductSortFilter",
  components: { ProductFacetFilterMobile, MultipleAction },
  mixins: [login],
  data() {
    return {
      sortOptions: [
        {
          value: "alf_asc",
          text: "A-Z"
        },
        {
          value: "alf_des",
          text: "Z-A"
        }
      ],
      viewOptions: [
        { value: "small-grid", text: "Griglia grande" },
        { value: "big-grid", text: "Griglia piccola" },
        { value: "list", text: "Elenco" }
      ]
    };
  },
  //props: ["sortFilter", "view"],
  props: {
    products: { type: Array, required: false },
    cart: { type: Boolean, required: false, default: false },
    sortFilter: String,
    view: String,
    actions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" },
    icon: { type: String, required: false, default: "mdi-filter-outline" },
    promoFilter: { type: Boolean, required: false },
    newFilter: { type: Boolean, required: false }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      selectedProducts: "selectedProducts/getProducts",
      totalItems: "cart/getItemsCount"
    }),
    status: {
      get() {
        return this.products?.every(product => product.highlight);
      },
      set() {}
    },
    allSelected() {
      return this.products?.every(product => product.highlight);
    },
    nothingSelected() {
      return this.selectedProducts.length === 0 ? true : false;
    }
  },
  methods: {
    ...mapActions({
      addProducts: "selectedProducts/addProducts",
      removeAllProducts: "selectedProducts/removeAllProducts",
      addProductsToCart: "cart/addProducts",
      addRemoveProducts: "cart/addRemoveProducts",
      emptyCart: "cart/emptyCart",
      setCartInfo: "cart/setCartInfo"
    }),
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handleView(view) {
      this.$emit("handleView", view);
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$emit("handleFacetFilterMobile", selectedFacetsXS);
    },
    async click(to) {
      var _this = this;
      if (_this.isAuthenticated) {
        if (to === "GetImages") {
          // if (await _this.needLogin("addtocart")) {
          //   // await this.addRemoveProducts(_this.selectedProducts);
          //   // await _this.removeAllProducts();
          // }
          console.log("SELECTED PROD", _this.selectedProducts);
          _this.$router.push({
            name: to,
            query: { cart: true }
          });
        } else if (to === "AddToCart") {
          console.log("AddToCart SELECTED PROD", _this.selectedProducts);
          if (await _this.needLogin("addtocart")) {
            await this.addProductsToCart(_this.selectedProducts);
            this.removeAllProducts();
            this.$emit("handleKey");
          }
        }
      } else _this.doLogin("imagesDownload");
    },
    async addAll() {
      if (this.status) {
        await this.removeAllProducts();
      } else {
        var forCart = this.products.map(product => ({
          product: product,
          quantity: 1
        }));
        //if (await this.needLogin("addtocart")) {
        await this.addProducts(forCart);
        //}
      }
      this.$emit("reload");
    },
    async addAllToCart() {
      if (await this.needLogin("addtocart")) {
        var forCart = this.products
          .filter(
            p =>
              p.inCart === false &&
              !(p.pubState === 1 && p.web_enabled === "false")
          )
          .map(product => ({
            product: product,
            quantity: 1
          }));
        await this.addProductsToCart(forCart);
        this.$emit("handleKey");
      }
    },
    emptyCartMethod() {
      this.emptyCart();
      this.setCartInfo({
        stepOne: "",
        stepTwo: "",
        stepThree: "",
        stepFour: ""
      });
    }
  }
};
</script>
<style scoped lang="scss">
.select {
  width: 188px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 50%;
    margin-bottom: 5px;
  }
}

.v-input--selection-controls {
  margin-top: 0px !important;
}
.custom-width {
  width: inherit;
  // @media #{map-get($display-breakpoints, 'md-and-down')} {
  //   width: 100%;
  //   padding-top: 5px;
  // }
}
</style>
