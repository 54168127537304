<template>
  <v-hover v-slot="{ hover }">
    <v-card
      outlined
      rounded="md"
      :class="{ 'on-hover': hover }"
      class="product-card"
    >
      <v-flex class="flex-column" @click="logClickProduct">
        <div class="select-product w-100 d-flex justify-space-between">
          <div
            v-if="product.pubState === 1 && product.web_enabled === 'false'"
            class="triangle"
          >
            <div class="triangle-text">{{ $t("common.delisted") }}</div>
          </div>
          <v-tooltip right :key="heartKey" v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToCarello(check)"
                @mousedown.stop
              >
                <v-icon color="primary" v-if="product.inCart">mdi-cart</v-icon>
                <v-icon v-else color="primary">mdi-cart-outline</v-icon>
              </v-btn>
            </template>
            {{
              product.inCart
                ? $t("product.removeFromCart")
                : $t("product.addToList")
            }}
          </v-tooltip>
          <!-- <v-checkbox
            v-if="product.inCart === false"
            v-model="product.highlight"
            @click.stop.prevent="addToCart"
            hide-details
          ></v-checkbox> -->
        </div>
        <v-row no-gutters justify="center" class="py-3">
          <v-img
            :src="product.mediaURL"
            contain
            :width="$vuetify.breakpoint.xs ? 110 : 170"
            :height="$vuetify.breakpoint.xs ? 110 : 170"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          ></v-img>
        </v-row>
        <v-flex class="flex-column">
          <v-row no-gutters justify="center" class="pa-3">
            <v-col cols="12" class="d-flex flex-column text-left product-info">
              <div class="label">
                {{ $t("advanceSearch.brand") }}
              </div>
              <div
                class="info-title"
                style="min-height: 25px;"
                v-html="product.shortDescr"
              ></div>
              <div class="label">
                {{ $t("product.details.description") }}
              </div>
              <div
                class="info-details mb-0"
                style="min-height: 72px;"
                v-html="product.name"
              ></div>
            </v-col>
          </v-row>
          <div class="d-flex flex-row justify-end px-3 pb-1">
            <v-btn
              color="primary"
              text
              small
              class="text-capitalize"
              :to="{ name: 'Product', params: { slug: product.slug } }"
              >{{ $t("common.details") }}
              <v-icon right>
                mdi-arrow-right
              </v-icon></v-btn
            >
            <!-- <v-menu top offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  outlined
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="text-capitalize"
                >
                  {{ $t("common.action") }}
                  <v-icon right>
                    mdi-dots-horizontal
                  </v-icon></v-btn
                >
              </template>
              <ActionNavigation :product="product" />
            </v-menu> -->
          </div>
        </v-flex>
      </v-flex>
    </v-card>
  </v-hover>
</template>

<script>
import productMixin from "~/mixins/product";

//import ActionNavigation from "@/components/navigation/ActionNavigation.vue";

import { mapState, mapGetters } from "vuex";
import { formatProductCode } from "@/service/marketsuiteUtils";

export default {
  name: "ProductCard",
  props: ["product", "position", "check"],
  //components: { ActionNavigation },
  mixins: [productMixin],
  data: () => ({}),
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    },
    productCode() {
      return formatProductCode(this.product);
    }
  },
  methods: {
    logClickProduct() {
      // this.$emit("productSelected");
    }
  }
};
</script>
<style scoped lang="scss">
.v-card {
  border-radius: 0.25rem !important;
  border: 2px solid $secondary;
}
.v-card:not(.on-hover) {
  border: 2px solid $primary-l2;
  border-radius: 0.25rem !important;
}
.v-input--selection-controls {
  margin-top: 0px !important;
}
.select-product {
  position: absolute;
  z-index: 1;
  right: 1px;
  top: 5px;
}
</style>
