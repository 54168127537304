<template>
  <v-container class="pa-0 filter-draver">
    <div class="pa-1 d-flex justify-start align-center">
      <v-btn @click="$emit('close')" color="primary" elevation="0" text>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <div class="d-flex justify-start pl-2">
      <v-btn color="link" text @click="handleFacetFilterMobile">
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        {{ $t("filter.button.applyFilter") }} </v-btn
      ><v-btn color="link" text @click="removeFilter">
        <v-icon left>
          mdi-minus-circle-outline
        </v-icon>
        {{ $t("filter.button.removeAllFilters") }}
      </v-btn>
    </div>
    <div v-for="facet in facetsFiltered" :key="facet.name" class="pa-3">
      <h3>{{ facet.name }}</h3>
      <div v-if="facet.paramType == 'Range'">
        <FilteredRangeSlider
          :facet="facet"
          :facetsFilter="facetsFilter"
          @handleFacetFilterMobile="handleRangeFilter"
        />
      </div>
      <v-chip-group
        v-else
        multiple
        class="product-filter"
        active-class="selected-filter"
        v-model="selectedFacetsXS[facet.parameterName]"
        column
      >
        <v-chip
          v-for="value in facet.values"
          :key="value.id"
          :value="value.id"
          label
        >
          {{ value.name }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-divider></v-divider>
    <div class="d-flex justify-start pl-2">
      <v-btn color="link" text @click="handleFacetFilterMobile">
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        {{ $t("filter.button.applyFilter") }} </v-btn
      ><v-btn color="link" text @click="removeFilter">
        <v-icon left>
          mdi-minus-circle-outline
        </v-icon>
        {{ $t("filter.button.removeAllFilters") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import FilteredRangeSlider from "./FilteredRangeSlider.vue";
export default {
  name: "ProductFilterDraver",
  components: { FilteredRangeSlider },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" }
  },
  methods: {
    handleFacetFilterMobile() {
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
      this.$emit("close");
    },
    removeFilter() {
      this.$emit("handleFacetFilterMobile", {});
    },
    handleRangeFilter(param) {
      Object.assign(this.selectedFacetsXS, param);
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
    }
  },
  computed: {
    facetsFiltered() {
      let vm = this;
      if (vm.facets) {
        return vm.facets.filter(
          item => vm.hideFilterString.indexOf(item.name) < 0
        );
      } else {
        return {};
      }
    },
    selectedFacetsXS() {
      return global.vm.lodash.cloneDeep(this.facetsFilter);
    }
  }
};
</script>
<style scoped lang="scss">
.selected-chip {
  background-color: $primary !important;
  color: #fff;
}
</style>
