<template>
  <div>
    <div>
      <span class="min-range">{{ limitRange.min }}</span
      ><span class="max-range"> {{ limitRange.max }}</span>
    </div>
    <v-range-slider
      v-if="limitRange && selectedRange && rangeIsNumber"
      :max="limitRange.max"
      :min="limitRange.min"
      v-model="range"
      :step="step"
      dense
      thumb-label="always"
      :thumb-size="45"
      track-color="primary lighten-1"
      :readonly="showOverlay"
      @end="handleFacetFilterMobile"
      class="custom-range-slider"
    ></v-range-slider>
    <div class="selected-range">{{ range[0] }} - {{ range[1] }}</div>
    <v-btn color="primary" block @click="resetRange" :disabled="rangeNotSet">
      {{ $t("filter.button.removeFilter") }}
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FilterRangeSlider",
  data() {
    return {
      panelLength: 6,
      range: [0, 100],
      step: 0.001
    };
  },
  props: {
    facet: { type: Object },
    facetsFilter: { type: Object }
  },
  computed: {
    ...mapGetters({
      showOverlay: "app/showOverlay"
    }),
    selectedFacetsXS() {
      return global.vm.lodash.cloneDeep(this.facetsFilter);
    },
    filter() {
      return this.facet.values.slice(0, this.panelLength);
    },
    selectedRange() {
      return this.facet.items.find(element => {
        return element.key == "filtered";
      });
    },
    limitRange() {
      return this.facet.items.find(element => {
        return element.key == "all";
      });
    },
    rangeIsNumber() {
      return (
        !isNaN(this.limitRange.max) &&
        !isNaN(parseFloat(this.limitRange.max)) &&
        !isNaN(this.limitRange.min) &&
        !isNaN(parseFloat(this.limitRange.min))
      );
    },
    minEqualMax() {
      return this.limitRange.max == this.limitRange.min;
    },
    rangeNotSet() {
      return (
        this.selectedFacetsXS[this.facet.parameterName + "_from"] ===
          undefined ||
        this.selectedFacetsXS[this.facet.parameterName + "_from"].length === 0
      );
    }
  },
  methods: {
    handleFacetFilterMobile() {
      this.handleFilter(this.range[0], this.range[1]);
    },
    getFilteredRange() {
      return this.facet.items.find(element => {
        return element.key == "filtered";
      });
    },
    resetRange() {
      this.handleFilter(null, null);
    },
    handleFilter(min, max) {
      let filter = global.vm.lodash.cloneDeep(this.facetsFilter);
      filter[this.facet.parameterName + "_from"] = min == null ? [] : [min];
      filter[this.facet.parameterName + "_to"] =
        max == null ? [] : [max + this.step / 2];
      this.$emit("handleFacetFilterMobile", filter);
    }
  },
  watch: {
    facet: function() {
      let f = this.getFilteredRange();
      this.range = [f.min, f.max];
    }
  },
  created() {
    let f = this.getFilteredRange();
    this.range = [f.min, f.max];
  }
};
</script>
<style scoped lang="scss">
.min-range {
  left: 10px;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
}
.max-range {
  right: 10px;
  position: relative;
  float: right;
  color: rgba(0, 0, 0, 0.6);
}
.selected-range {
  color: $primary;
  font-weight: 500;
  text-align: center;
}
</style>
