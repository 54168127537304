<template>
  <div class="desktop-filters-container">
    <v-btn
      color="primary"
      block
      @click="removeFilter"
      :disabled="showRemoveAll"
    >
      {{ $t("filter.button.removeAllFilters") }}
    </v-btn>
    <v-expansion-panels
      class="desktop-menu-filters"
      multiple
      flat
      v-model="panel"
    >
      <v-expansion-panel v-for="facet in facetsFiltered" :key="facet.name">
        <v-expansion-panel-header class="panel-header">
          <b class="primary--text font-weight-regular">{{ facet.name }}</b>
          <span
            class="grey--text caption"
            v-if="
              facetsFilter[facet.parameterName] &&
                facetsFilter[facet.parameterName].length > 0
            "
          >
            (+{{ facetsFilter[facet.parameterName].length }} filtri)
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="panel-content">
          <div v-if="facet.paramType == 'Range'">
            <FilteredRangeSlider
              :facet="facet"
              :facetsFilter="facetsFilter"
              @handleFacetFilterMobile="handleFacetFilterMobile"
            />
          </div>
          <filtered-chebox-list
            v-else
            :facet="facet"
            :facetsFilter="facetsFilter"
            @handleFacetFilterMobile="handleFacetFilterMobile"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import FilteredCheboxList from "./FilteredCheboxList.vue";
import FilteredRangeSlider from "./FilteredRangeSlider.vue";

export default {
  components: { FilteredCheboxList, FilteredRangeSlider },
  name: "ProductFacetFilterMobile",
  data() {
    return {
      dialog: false,
      panel: [0, 1, 2, 3],
      panelLength: [6, 6, 6, 6],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" }
  },
  computed: {
    facetsFiltered() {
      let vm = this;
      if (vm.facets) {
        return vm.facets.filter(
          item => vm.hideFilterString.indexOf(item.name) < 0
        );
      } else {
        return {};
      }
    },
    showRemoveAll() {
      if (Object.keys(this.facetsFilter).length == 0 || this.chechFilters()) {
        return true;
      }
      return false;
    }
  },
  methods: {
    handleFacetFilterMobile(param) {
      this.$emit("handleFacetFilterMobile", param);
      this.loadingApplyButton = true;
    },
    removeFilter() {
      this.$emit("handleFacetFilterMobile", {});
    },
    chechFilters() {
      let flag = true;
      Object.entries(this.facetsFilter).forEach(f => {
        if (f[1].length != 0) {
          flag = false;
          return;
        }
      });
      return flag;
    }
  }
};
</script>
