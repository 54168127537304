<template>
  <div style="max-height:100%" v-if="totalItems > 0">
    <v-row>
      <template v-for="(product, index) in products">
        <v-col cols="12" :key="product.productId" class="product-col">
          <ProductCardMobile
            v-if="product.product"
            v-bind:product="product.product"
            :position="position"
            :check="cart"
            @productSelected="onProductSelected(product, index)"
          />
          <ProductCardMobile
            v-else
            v-bind:product="product"
            :position="position"
            :check="cart"
            @productSelected="onProductSelected(product, index)"
          />
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import ProductCardMobile from "@/components/product/ProductCardMobile.vue";

export default {
  name: "ProductGridMobile",
  props: {
    products: { type: Array, required: true },
    totalItems: { type: Number, require: true },
    position: { type: String, required: false },
    cart: { type: Boolean, required: false, default: false }
  },
  data() {
    return {};
  },
  components: { ProductCardMobile },
  computed: {},
  methods: {
    onProductSelected(product, index) {
      this.selectedProductIndex = index;
      this.selectedProduct = product;
      this.calculateCardPosition();
    }
  }
};
</script>
