<template>
  <div style="max-height:100%" v-if="totalItems > 0">
    <v-row>
      <template v-for="(product, index) in products">
        <v-col
          cols="12"
          :xs="grid.cols"
          :sm="grid.sm"
          :md="grid.md"
          :lg="grid.lg"
          :xl="grid.xl"
          :key="product.productId"
          class="product-col"
        >
          <ProductCardBig
            v-if="product.product"
            v-bind:product="product.product"
            :position="position"
            :check="cart"
            @productSelected="onProductSelected(product.product, index)"
          />
          <ProductCardBig
            v-else
            v-bind:product="product"
            :position="position"
            :check="cart"
            @productSelected="onProductSelected(product, index)"
          />
        </v-col>
        <v-col
          v-if="
            selectedProduct &&
              largeCardPosition >= 0 &&
              largeCardPosition === index
          "
          :key="'d-' + product.productId"
          cols="12"
        >
          <div style="display:block; position:relative">
            <div
              class="product-short-detail"
              :style="{ marginLeft: arrowMargin, width: arrowDivWidth }"
            ></div>
            <ProductShortDetail :product="selectedProduct" />
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import ProductCardBig from "@/components/product/ProductCardBig.vue";
import ProductShortDetail from "@/components/product/ProductShortDetail.vue";

export default {
  name: "ProductGridBig",
  props: {
    products: { type: Array, required: true },
    totalItems: { type: Number, require: true },
    position: { type: String, required: false },
    cart: { type: Boolean, required: false, default: false }
  },
  components: { ProductCardBig, ProductShortDetail },
  mounted() {
    window.addEventListener("resize", this.calculateCardPosition, {
      passive: true
    });
  },
  data() {
    return {
      grid: {
        cols: 12,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6
      },
      selectedProduct: null,
      selectedProductIndex: -1,
      largeCardPosition: -1,
      selectedCardRowPosition: -1
    };
  },
  computed: {
    numberOfItemsInRow() {
      let productColNum = 12;
      if (this.$vuetify.breakpoint.xsOnly) {
        productColNum = this.grid.cols;
      } else if (this.$vuetify.breakpoint.smOnly) {
        productColNum = this.grid.sm;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        productColNum = this.grid.md;
      } else if (this.$vuetify.breakpoint.lgOnly) {
        productColNum = this.grid.lg;
      } else productColNum = this.grid.xl;
      return 12 / productColNum;
    },
    arrowMargin() {
      return (
        (this.selectedCardRowPosition / this.numberOfItemsInRow) * 100 + "%"
      );
    },
    arrowDivWidth() {
      return 100 / this.numberOfItemsInRow + "%";
    }
  },
  methods: {
    onProductSelected(product, index) {
      this.selectedProductIndex = index;
      this.selectedProduct = product;
      this.calculateCardPosition();
    },
    calculateCardPosition() {
      setTimeout(() => {
        if (this.selectedProductIndex < 0) return;
        this.selectedCardRowPosition =
          this.selectedProductIndex % this.numberOfItemsInRow;
        const numberOfItemsInSelectedRow =
          this.products.length >
            (Math.floor(this.selectedProductIndex / this.numberOfItemsInRow) +
              1) *
              this.numberOfItemsInRow ||
          this.products.length % this.numberOfItemsInRow === 0
            ? this.numberOfItemsInRow
            : this.products.length % this.numberOfItemsInRow;
        this.largeCardPosition =
          this.selectedProductIndex +
          numberOfItemsInSelectedRow -
          this.selectedCardRowPosition -
          1;
      }, 200);
    }
  }
};
</script>
<style scoped lang="scss">
.product-short-detail {
  position: relative;
}
.product-short-detail:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.75em solid transparent;
  border-bottom-color: $primary-l1;
  border-top: 0;
  margin-left: -0.75em;
  margin-top: -0.75em;
}
</style>
