<template>
  <v-list class="pb-0" subheader>
    <v-subheader class="pt-2 primary--text font-weight-bold">{{
      $t("common.action")
    }}</v-subheader>
    <v-divider></v-divider>
    <v-list-item @click="click('GetImages')" v-if="cart">
      <v-list-item-content>{{
        $t("product.actions.exportData")
      }}</v-list-item-content>
    </v-list-item>
    <v-list-item @click="click('AddToCart')" v-if="!cart">
      <v-list-item-content>{{ $t("product.addToList") }}</v-list-item-content>
    </v-list-item>
    <v-list-item @click="emptyCartMethod" v-if="cart">
      <v-list-item-content>{{
        $t("cart.button.emptyCart")
      }}</v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MultipleAction",
  props: { cart: { type: Boolean, required: false, default: false } },
  methods: {
    ...mapActions({
      addProducts: "selectedProducts/addProducts",
      removeAllProducts: "selectedProducts/removeAllProducts",
      addProductsToCart: "cart/addProducts",
      addRemoveProducts: "cart/addRemoveProducts",
      emptyCart: "cart/emptyCart",
      setCartInfo: "cart/setCartInfo"
    }),
    async click(to) {
      var _this = this;
      if (_this.isAuthenticated) {
        if (to === "GetImages") {
          // if (await _this.needLogin("addtocart")) {
          //   // await this.addRemoveProducts(_this.selectedProducts);
          //   // await _this.removeAllProducts();
          // }
          console.log("SELECTED PROD", _this.selectedProducts);
          _this.$router.push({
            name: to,
            query: { cart: true }
          });
        } else if (to === "AddToCart") {
          console.log("AddToCart SELECTED PROD", _this.selectedProducts);
          if (await _this.needLogin("addtocart")) {
            await this.addProductsToCart(_this.selectedProducts);
            this.removeAllProducts();
            this.$emit("handleKey");
          }
        }
      } else _this.doLogin("imagesDownload");
    },
    emptyCartMethod() {
      this.emptyCart();
      this.setCartInfo({
        stepOne: "",
        stepTwo: "",
        stepThree: "",
        stepFour: ""
      });
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      selectedProducts: "selectedProducts/getProducts"
    })
  }
};
</script>
