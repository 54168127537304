<template>
  <div class="products">
    <v-container>
      <div
        class="d-flex justify-end align-center"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <div>
          <v-select
            :items="sort"
            item-text="text"
            item-value="value"
            :value="sortFilter"
            label="Ordina per"
            @change="handleSortFilter"
          ></v-select>
        </div>
      </div>
      <div
        class="d-flex justify-space-between align-center primary lighten-2 mx-n3"
        v-else
      >
        <div class="pl-3">{{ $t("sort") }}</div>
        <v-btn-toggle
          :value="sortFilter"
          group
          color="primary"
          @change="handleSortFilter"
        >
          <v-btn class="round" value="alf_asc">
            <v-icon>mdi-sort-alphabetical-ascending</v-icon>
          </v-btn>

          <v-btn class="round" value="alf_dsc">
            <v-icon>mdi-sort-alphabetical-descending</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-container>
    <v-container>
      <v-row class="mb-8" v-if="$vuetify.breakpoint.smAndUp">
        <v-col
          v-for="subCategory in children"
          :key="subCategory.id"
          class="d-flex justify-center"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <CategoryCard :category="subCategory" :showContent="true" />
        </v-col>
      </v-row>
      <v-list v-else>
        <v-list-item
          v-for="category in children"
          :key="category.id"
          @click.prevent="handleLink(category.slug)"
        >
          <v-list-item-title>{{ category.name }}</v-list-item-title>
          <v-list-item-action>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import CategoryCard from "./CategoryCard.vue";
import categoryMixins from "~/mixins/category";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  mounted() {
    this.children = this.category.children.slice();
  },
  watch: {
    category() {
      this.children = this.category.children.slice();
    }
  },
  data() {
    return {
      children: [],
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        threshold: 10,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      select: null,
      sort: [
        { value: "alf_asc", text: "A-Z" },
        { value: "alf_dsc", text: "Z-A" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      sortFilter: "category/sortCategoryFilter"
    })
  },
  components: {
    CategoryCard
  },
  methods: {
    handleSortFilter(sort) {
      this.setCategorySort(sort);
      if (sort === "alf_asc") {
        this.children.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else {
        this.children.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      }
    },
    ...mapActions({
      setCategorySort: "category/setCategorySort"
    }),
    handleLink(url) {
      if (url) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: url }
        });
      }
    }
  },
  metaInfo() {
    return {
      title: this._.get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this._.get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        }
      ]
    };
  }
};
</script>
<style scoped>
.round {
  border-radius: 50% !important;
}
</style>
