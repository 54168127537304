var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.totalItems > 0)?_c('div',{staticStyle:{"max-height":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"item-key":this.products[0].product ? 'itemId' : 'productId',"server-items-length":_vm.totalItems,"single-expand":true,"expanded":_vm.expanded,"hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"header.bookmark",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.bookmark",fn:function(ref){
var item = ref.item;
return [(item.inCart === false)?_c('v-checkbox',{attrs:{"hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.bookmark(item)}},model:{value:(item.highlight),callback:function ($$v) {_vm.$set(item, "highlight", $$v)},expression:"item.highlight"}}):_vm._e()]}},{key:"item.mediaURLMedium",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.product ? item.product.mediaURL : item.mediaURL,"contain":"","height":70,"width":70}})]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [(item.product)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","text":"","to":{ name: 'Product', params: { slug: item.product.slug } }}},[_vm._v(_vm._s(_vm.$t("common.details"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1):_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","text":"","to":{ name: 'Product', params: { slug: item.slug } }}},[_vm._v(_vm._s(_vm.$t("common.details"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1)]}},{key:"item.inCart",fn:function(ref){
var item = ref.item;
return [(item.product)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removePopUp(item)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(item.product.inCart)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cart")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cart-outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.product.inCart ? _vm.$t("product.removeFromCart") : _vm.$t("product.addToList"))+" ")]):(item.pubState === 1 && item.web_enabled === 'false')?_c('div',{staticClass:"triangle"},[_c('v-chip',{attrs:{"color":"secondary","label":""}},[_vm._v(" "+_vm._s(_vm.$t("common.delisted"))+" ")])],1):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToCart(item)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(item.inCart)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cart")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cart-outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.inCart ? _vm.$t("product.removeFromCart") : _vm.$t("product.addToList"))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"white",attrs:{"colspan":headers.length}},[_c('ProductShortDetail',{attrs:{"product":item.product ? item.product : item,"headeWidht":headers.length}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }