<template>
  <div class="promo-filter py-4">
    <v-switch
      :input-value="promoFilter"
      inset
      label="In promozione"
      dense
      @change="handlePromoFilter"
      class="pt-2 mt-0"
      hide-details
    ></v-switch>
    <v-switch
      :input-value="newFilter"
      inset
      label="Novità"
      dense
      @change="handleNewFilter"
      class="pt-2 mt-0"
      hide-details
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: "ProductPromoFilter",
  props: ["promoFilter", "newFilter"],
  methods: {
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    }
  }
};
</script>
