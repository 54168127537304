<template>
  <v-card rounded="md" :href="link" @click.prevent="handleLink" elevation="2">
    <v-img :src="src" :alt="proposal.name" eager class="h-100" />
  </v-card>
</template>
<script>
import banner from "~/mixins/banner";
export default {
  name: "ProposalImage",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return this._.get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
