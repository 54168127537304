<template>
  <div
    class="swiper-pagination pagination"
    :class="paginationClass"
    slot="pagination"
  ></div>
</template>

<style lang="scss">
.pagination {
  z-index: 1;
  .swiper-pagination-bullet {
    margin: 0 3px;
    height: 4px;
    width: 30px;
    border-radius: 0%;
    background-color: var(--v-grey-darken3);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: var(--v-secondary-base);
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0 5px;
    }
  }
}
</style>

<script>
export default {
  props: ["paginationClass"]
};
</script>
