<template>
  <div>
    <v-card
      outlined
      rounded="md"
      :to="{ name: 'Product', params: { slug: product.slug } }"
      class="product-card"
    >
      <v-flex class="flex-column" @click="logClickProduct">
        <div class="select-product w-100 d-flex justify-space-between">
          <div
            v-if="product.pubState === 1 && product.web_enabled === 'false'"
            class="triangle"
          >
            <div class="triangle-text">{{ $t("common.delisted") }}</div>
          </div>
          <v-tooltip right :key="heartKey" v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToCarello(check)"
                @mousedown.stop
              >
                <v-icon color="primary" v-if="product.inCart">mdi-cart</v-icon>
                <v-icon v-else color="primary">mdi-cart-outline</v-icon>
              </v-btn>
            </template>
            {{
              product.inCart
                ? $t("product.removeFromCart")
                : $t("product.addToList")
            }}
          </v-tooltip>
          <!-- <v-checkbox
            v-if="product.inCart === false"
            v-model="product.highlight"
            @click.stop.prevent="addToCart"
            hide-details
          ></v-checkbox> -->
        </div>
        <v-row no-gutters justify="center" class="py-3">
          <v-img
            :src="product.mediaURL"
            contain
            :width="200"
            :height="200"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          ></v-img>
        </v-row>
        <v-flex class="flex-column">
          <v-row no-gutters justify="center" class="pa-3">
            <v-col cols="12" class="d-flex flex-column text-left product-info">
              <div class="label">
                {{ $t("advanceSearch.brand") }}
              </div>
              <div
                class="info-title"
                style="min-height: 25px;"
                v-html="product.shortDescr"
              ></div>
              <div class="label">
                {{ $t("product.details.description") }}
              </div>
              <div
                class="info-details"
                style="min-height: 60px;"
                v-html="description"
              ></div>
            </v-col>
          </v-row>
        </v-flex>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
import productMixin from "~/mixins/product";

import analyticsService from "~/service/analyticsService";

import { mapState, mapGetters } from "vuex";
import { formatProductCode } from "@/service/marketsuiteUtils";

export default {
  name: "ProductCardMobile",
  props: ["product", "position", "check"],
  components: {},
  mixins: [productMixin],
  data() {
    return {
      menu: false
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    },
    productCode() {
      return formatProductCode(this.product);
    },
    description() {
      return this.product.name + " " + this.product.description;
    }
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    }
  }
};
</script>
<style scoped>
.select-product {
  position: absolute;
  z-index: 1;
  right: 1px;
  top: 5px;
}
</style>
