<template>
  <v-hover v-slot="{ hover }">
    <v-card
      rounded="sm"
      flat
      elevation="0"
      width="250"
      height="90"
      :href="link"
      class="category-card"
      :class="{ 'on-hover': hover }"
      @click.prevent="handleLink"
    >
      <v-card-text class="d-flex justify-space-between align-center h-100">
        <div
          class="d-flex flex-column justify-space-around align-start pl-2 card-text"
          :class="`${hover ? 'text-white' : 'text-primary'}`"
          style="height:100%"
        >
          <h2>{{ category.name }}</h2>
          <div class="link--text" @click.stop.prevent="handleAllProductsLink">
            Vedi tutti prodotti
          </div>
        </div>
        <div>
          <v-icon large :class="`${hover ? 'text-white' : 'text-primary'}`"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
//import banner from "~/mixins/banner";

export default {
  name: "CategoryCard",
  props: {
    category: { type: Object, required: true }
  },
  computed: {
    link() {
      return this.category.proposalUrl || "/category/" + this.category.slug;
    }
  },
  methods: {
    handleLink() {
      if (this.category.slug) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: this.category.slug }
        });
      } else if (this.category.proposalUrl) {
        let link = new URL(this.category.proposalUrl, window.location.href);
        if (global.config.domainWhitelist.includes(link.hostname)) {
          let resolved = this.$router.resolve({
            path: link.pathname
          });
          if (
            resolved &&
            resolved.route.matched.length > 0 &&
            resolved.route.name != "DefaultRoute"
          ) {
            this.$router.push(resolved.route);
          }
        }
      }
    },
    handleAllProductsLink() {
      this.$router.push({
        name: "Category",
        query: { allProducts: true },
        params: { pathMatch: this.category.slug, type: "Products" }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.linkToLists {
  &:hover {
    cursor: pointer;
  }
}
.v-card {
  background-color: $primary;
  border-radius: 0.25rem !important;
}
.v-card:not(.on-hover) {
  background-color: white;
  border-radius: 0.25rem !important;
  border: 1px solid var(--v-primary-base);
}
.v-card__text {
  padding: 10px;
}
.text-white {
  color: white;
}
.text-primary {
  color: $primary !important;
}
.v-card--link:before {
  background: none;
}
.link--text {
  &:hover {
    color: white !important;
  }
}
.card-text {
  width: 88%;
  h2 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
