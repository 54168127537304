<template>
  <div style="max-height:100%" v-if="totalItems > 0">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="products"
      :item-key="this.products[0].product ? 'itemId' : 'productId'"
      :server-items-length="totalItems"
      :single-expand="true"
      :expanded.sync="expanded"
      hide-default-footer
      @click:row="clicked"
    >
      <template v-slot:[`header.bookmark`]="{ header }">
        {{ header.text }}
        <!-- <v-btn icon @click.stop.prevent="addAll()">
          <v-icon v-if="allSelected" color="primary">mdi-star</v-icon>
          <v-icon v-else color="primary">mdi-star-outline</v-icon>
        </v-btn> -->
        <!-- <v-checkbox
          v-model="allSelected"
          @click.stop.prevent="addAll()"
          hide-details
        ></v-checkbox> -->
      </template>
      <template v-slot:[`item.bookmark`]="{ item }">
        <!-- <v-btn icon @click.stop.prevent="bookmark(item)" @mousedown.stop>
          <v-icon color="primary" v-if="checkBookmark(item)">mdi-star</v-icon>
          <v-icon v-else color="primary">mdi-star-outline</v-icon>
        </v-btn> -->
        <v-checkbox
          v-if="item.inCart === false"
          v-model="item.highlight"
          @click.stop.prevent="bookmark(item)"
          hide-details
        ></v-checkbox>
      </template>
      <template v-slot:[`item.mediaURLMedium`]="{ item }">
        <v-img
          :src="item.product ? item.product.mediaURL : item.mediaURL"
          contain
          :height="70"
          :width="70"
        ></v-img>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <v-btn
          v-if="item.product"
          color="primary"
          text
          class="text-capitalize"
          :to="{ name: 'Product', params: { slug: item.product.slug } }"
          >{{ $t("common.details") }}
          <v-icon right>
            mdi-arrow-right
          </v-icon></v-btn
        >
        <v-btn
          v-else
          color="primary"
          text
          class="text-capitalize"
          :to="{ name: 'Product', params: { slug: item.slug } }"
          >{{ $t("common.details") }}
          <v-icon right>
            mdi-arrow-right
          </v-icon></v-btn
        >
      </template>
      <template v-slot:[`item.inCart`]="{ item }">
        <v-tooltip right v-if="item.product">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click.stop.prevent="removePopUp(item)"
              @mousedown.stop
            >
              <v-icon color="primary" v-if="item.product.inCart"
                >mdi-cart</v-icon
              >
              <v-icon v-else color="primary">mdi-cart-outline</v-icon>
            </v-btn>
          </template>
          {{
            item.product.inCart
              ? $t("product.removeFromCart")
              : $t("product.addToList")
          }}
        </v-tooltip>
        <div
          v-else-if="item.pubState === 1 && item.web_enabled === 'false'"
          class="triangle"
        >
          <v-chip color="secondary" label>
            {{ $t("common.delisted") }}
          </v-chip>
        </div>
        <v-tooltip right v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click.stop.prevent="addToCart(item)"
              @mousedown.stop
            >
              <v-icon color="primary" v-if="item.inCart">mdi-cart</v-icon>
              <v-icon v-else color="primary">mdi-cart-outline</v-icon>
            </v-btn>
          </template>
          {{
            item.inCart ? $t("product.removeFromCart") : $t("product.addToList")
          }}
        </v-tooltip>
      </template>
      <!-- <template v-slot:[`item.action`]="{ item }">
        <v-menu top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              v-on="on"
              class="text-capitalize"
              ><v-icon center>
                mdi-dots-horizontal
              </v-icon></v-btn
            >
          </template>
          <ActionNavigation :product="item.product ? item.product : item" />
        </v-menu>
      </template> -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="white">
          <ProductShortDetail
            :product="item.product ? item.product : item"
            :headeWidht="headers.length"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ProductShortDetail from "@/components/product/ProductShortDetail.vue";
import ConfirmDelete from "@/components/navigation/ConfirmDelete.vue";
import login from "@/commons/mixins/login";
import { mapActions } from "vuex";
//import ActionNavigation from "@/components/navigation/ActionNavigation.vue";

export default {
  name: "ProductTableViewTest",
  props: {
    products: { type: Array, required: true },
    totalItems: { type: Number, require: true },
    position: { type: String, required: false }
  },
  components: { ProductShortDetail },
  mixins: [login],
  data() {
    return {
      selected: [],
      expanded: [],
      product: null
    };
  },
  computed: {
    allSelected() {
      return this.products.every(product => product.highlight);
    },
    description() {
      return this.product.name + " " + this.product.description;
    },
    headers() {
      if (this.products && this.products[0].product) {
        return [
          // { text: "", value: "bookmark", sortable: false },
          { text: "", value: "mediaURLMedium", sortable: false },
          { text: "GTIN", value: "product.barcode", sortable: false },
          { text: "Brand", value: "product.shortDescr", sortable: false },
          { text: "Descrizione", value: "product.name", sortable: false },
          { text: "", value: "details", sortable: false },
          { text: "", value: "inCart", sortable: false, align: "center" }
          // { text: "", value: "action", sortable: false }
        ];
      }
      return [
        // { text: "", value: "bookmark", sortable: false },
        { text: "", value: "mediaURLMedium", sortable: false },
        { text: "GTIN", value: "barcode", sortable: false },
        { text: "Brand", value: "shortDescr", sortable: false },
        { text: "Descrizione", value: "name", sortable: false },
        { text: "", value: "details", sortable: false },
        { text: "", value: "inCart", sortable: false, align: "center" }
        // { text: "", value: "action", sortable: false }
      ];
    }
  },
  methods: {
    ...mapActions({
      addProduct: "selectedProducts/addProduct",
      addProducts: "cart/addProducts",
      removeItem: "selectedProducts/removeProduct",
      emptyCart: "cart/emptyCart",
      addProductToCart: "cart/addProduct",
      removeProductFromCart: "cart/removeItem"
    }),
    checkBookmark(item) {
      console.log(item.highlight);
      if (item.highlight || (item.product && item.product.highlight))
        return true;
      else return false;
    },
    checkInCart(item) {
      if (item.inCart || (item.product && item.product.inCart)) return false;
      else return true;
    },
    loadProduct(item) {
      this.$router.push("/product/" + item.slug);
    },
    clicked() {
      // this.expanded = [item];
    },
    async bookmark(product) {
      if (await this.needLogin("addtocart")) {
        if (this.checkBookmark(product)) {
          // product.highlight = true;
          await this.addProduct({
            product: product,
            quantity: 1
          });
        } else if (product.product) {
          // product.highlight = false;
          await this.removeItem(product.product);
        } else await this.removeItem(product);

        this.$emit("reload");
      }
    },
    async addAll() {
      if (this.allSelected) {
        await this.emptyCart();
      } else {
        var forCart = this.products.map(product => ({
          product: product,
          quantity: 1
        }));
        if (await this.needLogin("addtocart")) {
          await this.addProducts(forCart);
        }
      }
      this.$emit("reload");
    },
    async addToCart(product) {
      console.log(product);
      if (await this.needLogin("addtocart")) {
        if (this.checkInCart(product)) {
          await this.addProductToCart({
            product: product,
            quantity: 1
          });
        } else if (product.product) {
          await this.removeProductFromCart(product.product);
        } else await this.removeProductFromCart(product);

        this.$emit("reload");
      }
    },
    async removePopUp(item) {
      this.product = item.product;
      let result = await this.openDialog(ConfirmDelete, {
        waitForResult: true
      });
      if (result === "delete") {
        this.product.inCart = false;
        await this.removeProductFromCart(this.product);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.popup-title {
  color: $primary;
}
</style>
