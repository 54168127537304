<template>
  <div class="pr-3">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="mobileFilters"
          class="text-capitalize"
          color="primary"
          outlined
          elevation="0"
          :disabled="!facets"
          v-bind="attrs"
          v-on="on"
          height="40"
        >
          <span> {{ $tc("filter.filters", getFacetsLength) }}</span>
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <div class="pl-6 pt-3 white">
        <v-btn color="primary" @click="removeFilter" :disabled="showRemoveAll">
          {{ $t("filter.button.removeAllFilters") }}
        </v-btn>
      </div>
      <div
        class="d-flex justify-space-between white pa-3 custom-filter-menu"
        style="min-height:150px"
      >
        <div v-for="facet in facetsFiltered" :key="facet.name" class="px-3">
          <h3 class="primary--text font-weight-regular">{{ facet.name }}</h3>
          <div v-if="facet.paramType == 'Range'">
            <FilteredRangeSlider
              :facet="facet"
              :facetsFilter="facetsFilter"
              @handleFacetFilterMobile="handleFacetFilterMobile"
            />
          </div>
          <filtered-chebox-list
            v-else
            :facet="facet"
            :facetsFilter="facetsFilter"
            @handleFacetFilterMobile="handleFacetFilterMobile"
          />
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import FilteredCheboxList from "./FilteredCheboxList.vue";
import FilteredRangeSlider from "./FilteredRangeSlider.vue";

export default {
  name: "ProductFacetFilterMobile",
  components: { FilteredCheboxList, FilteredRangeSlider },
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {},

      fav: true,
      menu: false,
      message: false,
      hints: true
    };
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" },
    icon: { type: String, required: false, default: "mdi-filter-outline" },
    promoFilter: { type: Boolean, required: false },
    newFilter: { type: Boolean, required: false }
  },
  computed: {
    getFacetsLength() {
      // const reducedFacets = Object.keys(this.selectedFacetsXS).reduce
      let facetsLength = 0;
      for (const prop in this.selectedFacetsXS) {
        facetsLength += this.selectedFacetsXS[prop].length;
      }
      return facetsLength;
    },
    selectedFacetsXS() {
      return global.vm.lodash.cloneDeep(this.facetsFilter);
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    },
    showRemoveAll() {
      if (Object.keys(this.facetsFilter).length == 0 || this.chechFilters()) {
        return true;
      }
      return false;
    }
  },
  methods: {
    handleFacetFilterMobile(param) {
      this.$emit("handleFacetFilterMobile", param);
    },
    applyFilters() {
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = global.vm.lodash.cloneDeep(this.selectedFacetsXS);
      this.dialog = false;
      this.panel = [];
    },
    removeFilter() {
      this.$emit("handleFacetFilterMobile", {});
    },
    discard() {
      this.$emit("handleFacetFilterMobile", this.initialFacets);
      this.dialog = false;
      this.panel = [];
    },
    chechFilters() {
      let flag = true;
      Object.entries(this.facetsFilter).forEach(f => {
        if (f[1].length != 0) {
          flag = false;
          return;
        }
      });
      return flag;
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>
<style scoped>
.custom-filter-menu {
  overflow: auto;
}

.custom-filter-menu > div {
  min-width: 250px;
}
</style>
