import analyticsService from "~/service/analyticsService";

export default {
  props: {
    proposal: { type: Object, required: true },
    position: { type: String, required: false }
  },
  computed: {
    link() {
      return this.proposal.proposalUrl || "/category/" + this.proposal.slug;
    }
  },
  methods: {
    handleLink() {
      //log event to analytics
      analyticsService.clickPromotion(this.proposal, this.position);
      if (this.proposal.slug) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: this.proposal.slug }
        });
      } else if (this.proposal.proposalUrl) {
        let link = new URL(this.proposal.proposalUrl, window.location.href);
        //utilizzare stessa tecnica della page per capire se è un link interno o no....
        if (global.config.domainWhitelist.includes(link.hostname)) {
          let resolved = this.$router.resolve({
            path: link.pathname
          });
          if (
            resolved &&
            resolved.route.matched.length > 0 &&
            resolved.route.name != "DefaultRoute"
          ) {
            this.$router.push(resolved.route);
          } else {
            if (this.$platform_is_cordova && link.href.indexOf("http") > -1) {
              //if app open on _system browser
              window.cordova.InAppBrowser.open(link.href, "_system");
            }
          }
        } else {
          if (this.$platform_is_cordova && link.href.indexOf("http") > -1) {
            window.cordova.InAppBrowser.open(link.href, "_system");
          }
        }
      }
    },
    extractSlug(prefix, url) {
      let index = this.proposal.proposalUrl.lastIndexOf(prefix);
      if (index > -1) {
        let slug = url.slice(index + prefix.length, url.endsWith("/") ? -1 : 0);
        return slug;
      } else {
        return null;
      }
    }
  }
};
