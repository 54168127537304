<template>
  <v-list dense class="category-menu">
    <v-subheader class="text-primary d-none d-sm-flex">
      <h3>{{ category.name }}</h3>
    </v-subheader>
    <v-list-item-group color="primary"></v-list-item-group>
    <v-list-item
      class="list-item px-0"
      v-for="category2 in category.children"
      :key="category2.categoryId"
    >
      <router-link
        :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
      >
        {{ category2.name }}
      </router-link>
    </v-list-item>
  </v-list>
</template>

<script>
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "CategoryMenu",
  mixins: [deliveryReactive],
  props: { category: { type: Object, required: true } }
};
</script>
