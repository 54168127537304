<template>
  <v-list class="pb-0" subheader>
    <v-subheader class="pt-2 primary--text font-weight-bold">{{
      $t("common.action")
    }}</v-subheader>
    <v-divider></v-divider>
    <v-list-item @click="handleImageDownload">
      <v-list-item-content>{{
        $t("product.actions.exportData")
      }}</v-list-item-content>
    </v-list-item>
    <!-- <v-list-item @click="click">
      <v-list-item-content>{{
        $t("product.actions.getData")
      }}</v-list-item-content>
    </v-list-item>
    <v-subheader class="pt-2 primary--text font-weight-bold">{{
      $t("common.download")
    }}</v-subheader>
    <v-divider></v-divider>
    <v-list-item @click="click">
      <v-list-item-content>PDF</v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
import productMixin from "~/mixins/product";

export default {
  name: "ActionNavigator",
  props: { product: { type: Object, required: true } },
  mixins: [productMixin],
  methods: {
    click() {}
  },
  computed: {}
};
</script>
