<template>
  <div class="category" :class="{ white: templateName == 'categoryMed' }">
    <!-- <CategoryTitle :category="category" :count="count" /> -->
    <v-container class="pb-0">
      <div
        v-if="$vuetify.breakpoint.xs"
        class="back-buton-mobile"
        @click="$router.push(previusPage.to)"
      >
        <div v-if="previusPage">
          <v-icon color="link" small>mdi-arrow-up-left</v-icon>
          {{ previusPage.text }}
        </div>
      </div>
      <h1 class="primary--text pt-6">{{ category.name }}</h1></v-container
    >
    <Breadcrumb
      v-if="$vuetify.breakpoint.smAndUp"
      :items="breadcrumb"
      class="custom-breadcrumb"
    />
    <component
      :is="templateName"
      :key="key"
      @productsCount="updateCount"
    ></component>
    <!-- <v-container v-if="footerBanner.length > 0">
      <ProposalSlider :proposals="footerBanner" />
    </v-container> -->
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMain from "@/components/category/CategoryMain";
import categoryMed from "@/components/category/CategoryMed";
import categoryLeaflet from "@/components/category/CategoryLeaflet";
import categoryCustom from "@/components/category/CategoryCustom";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "Category",
  mixins: [categoryMixins, deliveryReactive],
  components: {
    categoryMain,
    categoryMed,
    categoryLeaflet,
    categoryCustom,
    ProposalSlider,
    CategoryTitle,
    Breadcrumb
  },
  data() {
    return {
      key: 1,
      count: -1,
      type: null
    };
  },
  computed: {
    templateName() {
      if (this.category.navigationTypeId == 1) {
        return "categoryLeaflet";
      } else if (
        this.category.children &&
        this.category.children.length > 0 &&
        this.type != "Products"
      ) {
        return "categoryMain";
      } else {
        return "categoryMed";
      }
    },
    previusPage() {
      if (this.breadcrumb.length > 1) {
        return this.breadcrumb[this.breadcrumb.length - 2];
      }
      return null;
    },
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    })
  },
  methods: {
    updateCount(count) {
      this.count = count;
    },
    async reload() {
      let slug = this._.split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  },
  async created() {
    if (this.$router.currentRoute.query.allProducts) {
      this.type = "Products";
    } else this.type = null;
    await this.resetFilters(this.category.slug);
  },
  watch: {
    async $route(to) {
      let slug = to.params.pathMatch;
      if (to.query && to.query.allProducts) {
        this.type = "Products";
      } else this.type = null;
      await this.resetFilters(slug);
      await this.setCategory({ slug });
    }
  }
};
</script>
