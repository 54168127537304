<template>
  <div :class="width ? 'test' : ''">
    <v-hover v-slot="{ hover }">
      <div
        class="white pa-3"
        :class="`${hover ? 'border-secondary' : 'border-primary'}`"
      >
        <v-row v-if="loadedProduct" class="fill-height">
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            order="1"
            order-sm="1"
            class="pa-3"
          >
            <v-card flat v-if="selectedImage">
              <ProductImages
                v-if="!$vuetify.breakpoint.smAndDown"
                :images="loadedProduct.media"
                :name="loadedProduct.name"
                :code="productCode"
                :key="loadedProduct.productId"
                :highlight="highlight"
                :heartKey="heartKey"
                @toggleFavourites="addToFavorites"
              />
              <ProductImagesSlider
                v-else
                :images="loadedProduct.media"
                :name="loadedProduct.name"
              />
            </v-card>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            order="2"
            order-sm="2"
            class="d-flex flex-column justify-space-between pa-3 primary lighten-2"
          >
            <v-row class="primary lighten-2" no-gutters>
              <v-col
                xl="6"
                lg="6"
                cols="6"
                md="6"
                order="2"
                order-sm="2"
                class="pa-3 product-info"
              >
                <div class="label">
                  {{ $t("product.details.agency") }}
                </div>
                <div class="info-details" style="min-height: 20px;">
                  {{ product.shortDescr }}
                </div>
                <div class="label">
                  {{ $t("common.gtin") }}
                </div>
                <div class="info-details" style="min-height: 20px;">
                  <span>{{ product.code }}</span>
                </div>
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                cols="6"
                order="2"
                order-sm="2"
                class="pa-3 product-info"
              >
                <div class="label">
                  {{ $t("advanceSearch.brand") }}
                </div>
                <div class="info-details" style="min-height: 20px;">
                  {{ product.shortDescr }}
                </div>
                <div class="label">
                  {{ $t("product.details.versionDate") }}
                </div>
                <div class="info-details" style="min-height: 20px;">
                  <span> </span>
                </div>
              </v-col>
              <v-col cols="12" order="3" class="px-3 fill-height product-info"
                ><div class="label">
                  {{ $t("product.details.description") }}
                </div>
                <div class="info-details" style="min-height: 60px;">
                  {{ description }}
                </div></v-col
              >
            </v-row>

            <div class="d-flex flex-row justify-space-between pa-1">
              <v-btn
                color="primary"
                text
                class="text-capitalize"
                :to="{ name: 'Product', params: { slug: product.slug } }"
                >{{ $t("common.details") }}
                <v-icon right>
                  mdi-arrow-right
                </v-icon></v-btn
              >
              <v-menu top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                  >
                    {{ $t("common.action") }}
                    <v-icon right>
                      mdi-dots-horizontal
                    </v-icon></v-btn
                  >
                </template>
                <ActionNavigation :product="product" />
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-hover>
  </div>
</template>
<script>
import ProductImages from "@/components/product/ProductImages.vue";
import ProductImagesSlider from "@/components/product/ProductImagesSlider.vue";
import LongPress from "vue-directive-long-press";

import productMixin from "~/mixins/product";
import ProductService from "~/service/productService";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { formatProductCode } from "@/service/marketsuiteUtils";
import ActionNavigation from "@/components/navigation/ActionNavigation.vue";

export default {
  name: "ProductShortDetail",
  props: ["product", "headeWidht"],
  components: {
    ProductImages,
    ActionNavigation,
    ProductImagesSlider
  },
  mixins: [productMixin],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  data() {
    return {
      loadedProduct: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1
    };
  },
  async mounted() {
    this.slug = this.product.slug;
    this.showNoProductCard = false;
    await this.getProduct();
  },
  watch: {
    async product() {
      this.slug = this.product.slug;
      this.showNoProductCard = false;
      await this.getProduct();
    }
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated"
    }),
    width() {
      if (this.headeWidht) {
        return true;
      }
      return false;
    },
    // productClasses() {
    //   return this.product &&
    //     this.product.productClasses &&
    //     this.product.productClasses.length
    //     ? this.product.productClasses.filter(p => p.productClassId !== 10078)
    //     : [];
    // },
    productCode() {
      return formatProductCode(this.loadedProduct);
    },
    productFullName() {
      return (
        this.product.shortDescr +
        " " +
        this.product.name +
        " " +
        this.product.description
      );
    },
    description() {
      return this.product.name + " " + this.product.description;
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.loadedProduct = data;
        if (this.loadedProduct.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (
          this.loadedProduct &&
          this.loadedProduct.media &&
          this.loadedProduct.media.length
        ) {
          this.selectImage(this.loadedProduct.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    }
  }
};
</script>
<style scoped lang="scss">
.border-primary {
  border: 2px solid $primary-l2 !important;
  border-radius: 0.25rem;
}
.border-secondary {
  border: 2px solid $secondary !important;
  border-radius: 0.25rem;
}
.test {
  @media screen and (min-width: 576px) {
    max-width: 480px;
  }
  @media screen and (min-width: 768px) {
    max-width: 660px;
  }
  @media screen and (min-width: 992px) {
    max-width: 920px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1080px;
  }
}
</style>
